import 'core-js/stable'
import 'regenerator-runtime/runtime'

import $ from 'jquery';
window.jQuery = $;
require.context('../images', true);
import '../src/application.scss';
import '../src/select2/js/select2.full.min';

$(document).ready(function () {
  $('.JS-select2').select2({
    placeholder: "např. Chrudim",
    allowClear: true
  });
  $('.JS-select2-target-groups').select2({
    allowClear: false,
    multiple: true,
    placeholder: "např. pro seniory (můžete vybrat více možností)"
  });

});


$(window).scroll(function () {
  var scroll = $(window).scrollTop();

  if (scroll >= 100) {
    $("body").addClass("JS-scrolled");
  }
  else {
    $("body").removeClass("JS-scrolled");
  }
});